import React from 'react';
import Slider from 'react-slick';
import Section from '@components/core/Section';
import Quotes from '@components/Quotes/Quotes';
import PropTypes from 'prop-types';
import styles from './testimony.module.scss';

const formatData = testimoniesData => {
    return testimoniesData.map((testimony, index) => ({
        key: index,
        author: testimony.author,
        testimony: testimony.testimony,
        image: testimony.image.childImageSharp.fluid,
    }));
};

const Testimony = ({ testimoniesList }) => {
    const formatedTestimonies =
        testimoniesList && testimoniesList.length > 0
            ? formatData(testimoniesList)
            : [];
    const settings = {
        cssEase: 'linear',
        arrows: false,
        autoplay: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        autoplaySpeed: 20000,
        pauseOnHover: false,
    };

    return (
        <Section variant="dark" dataTheme="dark" className={styles.section}>
            <Slider {...settings}>
                {formatedTestimonies.length > 0 &&
                    formatedTestimonies.map(
                        ({ key, author, testimony, image }) => (
                            <Quotes
                                key={`slider-play-station-${key}`}
                                author={author}
                                testimony={testimony}
                                image={image}
                            />
                        )
                    )}
            </Slider>
        </Section>
    );
};

Testimony.propTypes = {
    testimoniesList: PropTypes.arrayOf(
        PropTypes.shape({
            author: PropTypes.string,
            testimony: PropTypes.string,
            image: PropTypes.shape({
                childImageSharp: PropTypes.shape({
                    title: PropTypes.string,
                    alt: PropTypes.string,
                    fluid: PropTypes.shape({
                        aspectRatio: PropTypes.number.isRequired,
                        src: PropTypes.string.isRequired,
                        srcSet: PropTypes.string.isRequired,
                        sizes: PropTypes.string.isRequired,
                        base64: PropTypes.string,
                        tracedSVG: PropTypes.string,
                        srcWebp: PropTypes.string,
                        srcSetWebp: PropTypes.string,
                        media: PropTypes.string,
                        maxWidth: PropTypes.number,
                        maxHeight: PropTypes.number,
                    }),
                }),
            }),
        })
    ).isRequired,
};

export default Testimony;

import React from 'react';
import Layout from '@components/Layout';
import SEO from '@components/SEO';
import Header from '@sections/CaseStudyDetail/PlayStation/Header';
import Overview from '@sections/CaseStudyDetail/Overview';
import AppliedStrategy from '@sections/CaseStudyDetail/AppliedStrategy';
import ProductStrategyAndDesign from '@sections/CaseStudyDetail/ProductStrategyAndDesign';
import RoadMapping from '@sections/CaseStudyDetail/Ott/RoadMapping';
import ContentStrategy from '@sections/CaseStudyDetail/ContentStrategy';
import Content from '@sections/CaseStudyDetail/Content';
import Testimony from '@sections/CaseStudyDetail/Testimony';
import TechPartners from '@sections/CaseStudyDetail/Ott/TechPartners';
import ProductLifeCycle from '@sections/CaseStudyDetail/ProductLifeCycle';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';

const Ott = ({
    data: {
        caseStudyInfo: {
            frontmatter: {
                appliedStrategy,
                capabilities,
                contentStrategy,
                content,
                header,
                overviewSection,
                productStrategyAndDesign,
                roadMapping,
                experience,
                seo,
                testimonies,
                title,
                techPartners,
                userExperience,
            },
        },
    },
}) => {
    const seoData = {
        title: (seo && seo.title) || title,
        description: (seo && seo.description) || '',
        image: (seo && seo.image && seo.image.publicURL) || '',
    };

    return (
        <Layout navBarTheme="dark">
            <SEO
                title={seoData.title}
                description={seoData.description}
                image={seoData.image}
            />
            <Header {...header} />
            <Overview capabilities={capabilities} overview={overviewSection} />
            <AppliedStrategy {...appliedStrategy} />
            <ProductStrategyAndDesign {...productStrategyAndDesign} />
            <Testimony {...testimonies} />
            <RoadMapping experience={experience} roadMapping={roadMapping} />
            <ContentStrategy {...contentStrategy} />
            <Content {...content} />
            <TechPartners data={techPartners} />
            <ProductLifeCycle {...userExperience} />
        </Layout>
    );
};

Ott.propTypes = {
    data: PropTypes.shape({
        caseStudyInfo: PropTypes.objectOf(PropTypes.any),
    }).isRequired,
};

export default Ott;

export const pageQuery = graphql`
    query caseStudyOtt($id: String!) {
        caseStudyInfo: markdownRemark(id: { eq: $id }) {
            frontmatter {
                seo {
                    title
                    description
                    image {
                        publicURL
                    }
                }
                title
                published(formatString: "DD MMMM YYYY")
                header {
                    sectionHeader {
                        title
                        eyebrow
                        description
                    }
                    logo {
                        childImageSharp {
                            fluid {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                    mainImage {
                        childImageSharp {
                            fluid(maxWidth: 1200) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                    secondaryImage {
                        childImageSharp {
                            fluid(maxWidth: 632) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
                overviewSection {
                    sectionHeader {
                        title
                        eyebrow
                        description
                    }
                }
                capabilities {
                    sectionHeader {
                        title
                        eyebrow
                    }
                    subTitle
                    capabilities {
                        capability
                    }
                }
                appliedStrategy {
                    sectionHeader {
                        title
                        eyebrow
                        description
                    }
                    image {
                        childImageSharp {
                            fluid(maxWidth: 1200) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
                productStrategyAndDesign {
                    sectionHeader {
                        title
                        eyebrow
                        description
                    }
                    image {
                        childImageSharp {
                            fluid(maxWidth: 1200) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
                roadMapping {
                    sectionHeader {
                        title
                        eyebrow
                        description
                    }
                    image {
                        childImageSharp {
                            fluid(maxWidth: 1200) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
                experience {
                    bullets {
                        bullet
                    }
                }
                contentStrategy {
                    sectionHeader {
                        title
                        eyebrow
                        description
                    }
                    image {
                        childImageSharp {
                            fluid(maxWidth: 1200) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                    imageMobile {
                        childImageSharp {
                            fluid(maxWidth: 1200) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
                content {
                    sectionHeader {
                        title
                        eyebrow
                        description
                    }
                    image {
                        childImageSharp {
                            fluid(maxWidth: 1200, quality: 70) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
                testimonies {
                    testimoniesList {
                        author
                        testimony
                        image {
                            childImageSharp {
                                fluid(maxWidth: 140) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                }
                techPartners {
                    sectionHeader {
                        title
                        eyebrow
                        description
                    }
                }
                userExperience {
                    sectionHeader {
                        title
                        eyebrow
                        description
                    }
                }
            }
            htmlAst
        }
    }
`;

import React from 'react';
import Section from '@components/core/Section';
import Grid, { Row, Column } from '@components/core/Grid';
import SectionHeader from '@components/SectionHeader';
import PropTypes from 'prop-types';
import Text from '@components/core/Text';
import Img from 'gatsby-image';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import styles from './content-strategy.module.scss';

const ContentStrategy = ({
    sectionHeader: { eyebrow, title, description },
    image: {
        childImageSharp: { fluid },
    },
    imageMobile: {
        childImageSharp: { fluid: fluidMobile },
    },
}) => {
    const { md } = useBreakpoint();
    return (
        <Section variant="dark" dataTheme="dark" className={styles.section}>
            <Grid>
                <div className={styles.header}>
                    <Row>
                        <Column md="6" offsetMD="1">
                            <SectionHeader
                                className={styles.sectionHeader}
                                variant="dark"
                                eyebrow={eyebrow}
                                title={title}
                                sizeTitle="h3"
                            />
                        </Column>
                        <Column md="5">
                            <Text
                                as="p"
                                size="paragraphMd"
                                color="white"
                                className={styles.summary}
                            >
                                {description}
                            </Text>
                        </Column>
                    </Row>
                </div>
                <div className={styles.imageWrapper}>
                    <Img fluid={md ? fluid : fluidMobile} />
                </div>
            </Grid>
        </Section>
    );
};

ContentStrategy.propTypes = {
    sectionHeader: PropTypes.shape({
        eyebrow: PropTypes.string,
        title: PropTypes.string,
        description: PropTypes.string,
    }).isRequired,
    image: PropTypes.shape({
        childImageSharp: PropTypes.shape({
            fluid: PropTypes.shape({
                aspectRatio: PropTypes.number.isRequired,
                src: PropTypes.string.isRequired,
                srcSet: PropTypes.string.isRequired,
                sizes: PropTypes.string.isRequired,
                base64: PropTypes.string,
                tracedSVG: PropTypes.string,
                srcWebp: PropTypes.string,
                srcSetWebp: PropTypes.string,
                media: PropTypes.string,
                maxWidth: PropTypes.number,
                maxHeight: PropTypes.number,
            }),
        }),
    }).isRequired,
    imageMobile: PropTypes.shape({
        childImageSharp: PropTypes.shape({
            fluid: PropTypes.shape({
                aspectRatio: PropTypes.number.isRequired,
                src: PropTypes.string.isRequired,
                srcSet: PropTypes.string.isRequired,
                sizes: PropTypes.string.isRequired,
                base64: PropTypes.string,
                tracedSVG: PropTypes.string,
                srcWebp: PropTypes.string,
                srcSetWebp: PropTypes.string,
                media: PropTypes.string,
                maxWidth: PropTypes.number,
                maxHeight: PropTypes.number,
            }),
        }),
    }).isRequired,
};

export default ContentStrategy;

import React from 'react';
import Section from '@components/core/Section';
import Grid, { Column, Row } from '@components/core/Grid';
import SectionHeader from '@components/SectionHeader';
import PropTypes from 'prop-types';
import Text from '@components/core/Text';
import Image from 'gatsby-image';
import styles from './header.module.scss';

const Header = ({
    sectionHeader: { eyebrow, title, description },
    mainImage,
    secondaryImage,
    logo,
}) => {
    return (
        <Section className={styles.header} dataTheme="dark">
            <div className={styles.sectionDark} />
            <Grid>
                <Row>
                    <Column col="4" md="8">
                        <SectionHeader
                            variant="dark"
                            className={styles.sectionHeader}
                            eyebrow={eyebrow}
                            title={title}
                        />
                    </Column>
                    {logo && (
                        <Column
                            col="1"
                            md="1"
                            offsetMd="3"
                            className={styles.summaryWrapper}
                        >
                            <div className={styles.logoContainer}>
                                <Image fluid={logo.childImageSharp.fluid} />
                            </div>
                        </Column>
                    )}
                </Row>
                {mainImage && (
                    <div className={styles.imageContainer}>
                        <div className={styles.mainImage}>
                            <Image
                                className={styles.image}
                                fluid={mainImage.childImageSharp.fluid}
                                title={mainImage.childImageSharp.title}
                                alt={mainImage.childImageSharp.alt}
                            />
                        </div>
                    </div>
                )}
            </Grid>
            <div data-theme="light">
                <Grid>
                    <Row className={styles.reverseRow}>
                        <Column md="6">
                            <Text as="p" size="paragraphMd">
                                {description}
                            </Text>
                        </Column>
                        {secondaryImage && (
                            <Column
                                md="5"
                                offsetMd="1"
                                className={styles.imageColumn}
                            >
                                <div className={styles.secondaryImageContainer}>
                                    <Image
                                        fluid={
                                            secondaryImage.childImageSharp.fluid
                                        }
                                    />
                                </div>
                            </Column>
                        )}
                    </Row>
                </Grid>
            </div>
        </Section>
    );
};

Header.propTypes = {
    sectionHeader: PropTypes.shape({
        eyebrow: PropTypes.string,
        title: PropTypes.string,
        description: PropTypes.string,
    }).isRequired,
    logo: PropTypes.shape({
        childImageSharp: PropTypes.shape({
            title: PropTypes.string,
            alt: PropTypes.string,
            fluid: PropTypes.shape({
                aspectRatio: PropTypes.number.isRequired,
                src: PropTypes.string.isRequired,
                srcSet: PropTypes.string.isRequired,
                sizes: PropTypes.string.isRequired,
                base64: PropTypes.string,
                tracedSVG: PropTypes.string,
                srcWebp: PropTypes.string,
                srcSetWebp: PropTypes.string,
                media: PropTypes.string,
                maxWidth: PropTypes.number,
                maxHeight: PropTypes.number,
            }),
        }),
    }).isRequired,
    mainImage: PropTypes.shape({
        childImageSharp: PropTypes.shape({
            title: PropTypes.string,
            alt: PropTypes.string,
            fluid: PropTypes.shape({
                aspectRatio: PropTypes.number.isRequired,
                src: PropTypes.string.isRequired,
                srcSet: PropTypes.string.isRequired,
                sizes: PropTypes.string.isRequired,
                base64: PropTypes.string,
                tracedSVG: PropTypes.string,
                srcWebp: PropTypes.string,
                srcSetWebp: PropTypes.string,
                media: PropTypes.string,
                maxWidth: PropTypes.number,
                maxHeight: PropTypes.number,
            }),
        }),
    }).isRequired,
    secondaryImage: PropTypes.shape({
        childImageSharp: PropTypes.shape({
            title: PropTypes.string,
            alt: PropTypes.string,
            fluid: PropTypes.shape({
                aspectRatio: PropTypes.number.isRequired,
                src: PropTypes.string.isRequired,
                srcSet: PropTypes.string.isRequired,
                sizes: PropTypes.string.isRequired,
                base64: PropTypes.string,
                tracedSVG: PropTypes.string,
                srcWebp: PropTypes.string,
                srcSetWebp: PropTypes.string,
                media: PropTypes.string,
                maxWidth: PropTypes.number,
                maxHeight: PropTypes.number,
            }),
        }),
    }).isRequired,
};

export default Header;

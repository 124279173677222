import React from 'react';
import Section from '@components/core/Section';
import SectionHeader from '@components/SectionHeader';
import Grid, { Column, Row } from '@components/core/Grid';
import Eyebrow from '@components/core/Eyebrow';
import Text from '@components/core/Text';
import PropTypes from 'prop-types';
import styles from './overview.module.scss';

const Overview = ({
    overview: { sectionHeader: overViewSH },
    capabilities: {
        sectionHeader: { eyebrow, title },
        subTitle,
        capabilities,
        platforms,
    },
}) => {
    return (
        <Section className={styles.header}>
            <Grid>
                <Row>
                    <Column md="7">
                        <SectionHeader
                            eyebrow={overViewSH.eyebrow}
                            summary={overViewSH.description}
                            title={overViewSH.title}
                            sizeTitle="h3"
                        />
                    </Column>
                    <Column md="3" offsetMd="2" className={styles.platforms}>
                        {eyebrow && (
                            <Eyebrow
                                className={styles.eyebrow}
                                casing="uppercase"
                                size="paragraphSm"
                            >
                                {eyebrow}
                            </Eyebrow>
                        )}
                        {title && (
                            <Text className={styles.title} size="h3" as="h3">
                                {title}
                            </Text>
                        )}
                        {capabilities && capabilities.length > 0 && (
                            <ul className={styles.list}>
                                {capabilities.map(({ capability }) => (
                                    <li
                                        className={styles.listSquare}
                                        key={capability}
                                    >
                                        <Text as="p" size="paragraphSm">
                                            {capability}
                                        </Text>
                                    </li>
                                ))}
                            </ul>
                        )}
                        <Text
                            className={styles.subTitle}
                            as="p"
                            size="paragraphMd"
                        >
                            {subTitle}
                        </Text>
                        {platforms &&
                            platforms.length > 0 &&
                            platforms.map(({ platform }) => (
                                <Text as="p" size="paragraphSm" color="gray">
                                    {platform}
                                </Text>
                            ))}
                    </Column>
                </Row>
            </Grid>
        </Section>
    );
};

Overview.propTypes = {
    overview: PropTypes.shape({
        sectionHeader: PropTypes.shape({
            eyebrow: PropTypes.string,
            title: PropTypes.string,
            description: PropTypes.string,
        }).isRequired,
    }).isRequired,
    capabilities: PropTypes.shape({
        sectionHeader: PropTypes.shape({
            eyebrow: PropTypes.string,
            title: PropTypes.string,
            description: PropTypes.string,
        }).isRequired,
        subTitle: PropTypes.string.isRequired,
        capabilities: PropTypes.arrayOf(PropTypes.string).isRequired,
        platforms: PropTypes.arrayOf(PropTypes.string).isRequired,
    }).isRequired,
};

export default Overview;

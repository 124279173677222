import React from 'react';
import Section from '@components/core/Section';
import Grid, { Row, Column } from '@components/core/Grid';
import Text from '@components/core/Text';
import PropTypes from 'prop-types';
import Image from 'gatsby-image';
import SectionHeader from '@components/SectionHeader';
import Markdown from 'react-remarkable';
import styles from './applied-strategy.module.scss';

const AppliedStrategy = ({
    sectionHeader: { eyebrow, title, description },
    image,
}) => {
    return (
        <Section variant="dark" dataTheme="dark" className={styles.section}>
            <Grid>
                <SectionHeader
                    className={styles.sectionHeader}
                    classTitleWrapper={styles.headerTitle}
                    eyebrow={eyebrow}
                    sizeTitle="h3"
                    variant="dark"
                    title={title}
                />
                <Row className={styles.reverseRow}>
                    <Column md="6">
                        <Image fluid={image.childImageSharp.fluid} />
                    </Column>
                    <Column
                        md="5"
                        offsetMd="1"
                        className={styles.descriptionColumn}
                    >
                        <Text
                            className={styles.summary}
                            as="div"
                            size="paragraphMd"
                            color="white"
                        >
                            <Markdown>{description}</Markdown>
                        </Text>
                    </Column>
                </Row>
            </Grid>
        </Section>
    );
};

AppliedStrategy.propTypes = {
    sectionHeader: PropTypes.shape({
        eyebrow: PropTypes.string,
        title: PropTypes.string,
        description: PropTypes.string,
    }).isRequired,
    image: PropTypes.shape({
        childImageSharp: PropTypes.shape({
            title: PropTypes.string,
            alt: PropTypes.string,
            fluid: PropTypes.shape({
                aspectRatio: PropTypes.number.isRequired,
                src: PropTypes.string.isRequired,
                srcSet: PropTypes.string.isRequired,
                sizes: PropTypes.string.isRequired,
                base64: PropTypes.string,
                tracedSVG: PropTypes.string,
                srcWebp: PropTypes.string,
                srcSetWebp: PropTypes.string,
                media: PropTypes.string,
                maxWidth: PropTypes.number,
                maxHeight: PropTypes.number,
            }),
        }),
    }).isRequired,
};

export default AppliedStrategy;

import React from 'react';
import Section from '@components/core/Section';
import Grid, { Row, Column } from '@components/core/Grid';
import SectionHeader from '@components/SectionHeader';
import PropTypes from 'prop-types';
import Text from '@components/core/Text';
import Img from 'gatsby-image';
import Markdown from 'react-remarkable';
import styles from './product-strategy-and-design.module.scss';

const ProductStrategyAndDesign = ({
    sectionHeader: { eyebrow, title, description },
    image: {
        childImageSharp: { fluid },
    },
}) => {
    return (
        <Section className={styles.section}>
            <Grid>
                <div className={styles.header}>
                    <Row>
                        <Column md="5" offsetMd="1">
                            <SectionHeader
                                className={styles.sectionHeader}
                                eyebrow={eyebrow}
                                title={title}
                                sizeTitle="h3"
                            />
                        </Column>
                        <Column md="5" offsetMd="1">
                            <Text
                                className={styles.summary}
                                as="div"
                                size="paragraphMd"
                            >
                                <Markdown>{description}</Markdown>
                            </Text>
                        </Column>
                    </Row>
                </div>
            </Grid>
            <div className={styles.featuredImage}>
                <div className={styles.blackStripe} data-theme="dark" />
                <Grid>
                    <Img fluid={fluid} />
                </Grid>
            </div>
        </Section>
    );
};

ProductStrategyAndDesign.propTypes = {
    sectionHeader: PropTypes.shape({
        eyebrow: PropTypes.string,
        title: PropTypes.string,
        description: PropTypes.string,
    }).isRequired,
    image: PropTypes.shape({
        childImageSharp: PropTypes.shape({
            fluid: PropTypes.shape({
                aspectRatio: PropTypes.number.isRequired,
                src: PropTypes.string.isRequired,
                srcSet: PropTypes.string.isRequired,
                sizes: PropTypes.string.isRequired,
                base64: PropTypes.string,
                tracedSVG: PropTypes.string,
                srcWebp: PropTypes.string,
                srcSetWebp: PropTypes.string,
                media: PropTypes.string,
                maxWidth: PropTypes.number,
                maxHeight: PropTypes.number,
            }),
        }),
    }).isRequired,
};

export default ProductStrategyAndDesign;

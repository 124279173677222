import React from 'react';
import Section from '@components/core/Section';
import Grid, { Row, Column } from '@components/core/Grid';
import Text from '@components/core/Text';
import SectionHeader from '@components/SectionHeader';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import styles from './road-mapping.module.scss';

const CHUNK_SIZE = 4;

const RoadMapping = ({
    roadMapping: {
        sectionHeader: { eyebrow, title, description },
        image: {
            childImageSharp: { fluid },
        },
    },
    experience: { bullets },
}) => {
    const bulletsChunks =
        Array.isArray(bullets) &&
        Array.from(
            { length: Math.ceil(bullets.length / CHUNK_SIZE) },
            (_, index) =>
                bullets.slice(
                    index * CHUNK_SIZE,
                    index * CHUNK_SIZE + CHUNK_SIZE
                )
        );
    return (
        <Section className={styles.section}>
            <Grid>
                <Row className={styles.row}>
                    <Column md="5" offsetMd="1">
                        <SectionHeader
                            className={styles.header}
                            eyebrow={eyebrow}
                            title={title}
                            sizeTitle="h3"
                            summary={description}
                        />
                    </Column>
                    <Column md="6">
                        <Img fluid={fluid} />
                    </Column>
                </Row>
                <Row>
                    {Array.isArray(bulletsChunks) &&
                        bulletsChunks.map(bulletChunk => (
                            <Column md="4">
                                <ul>
                                    {bulletChunk.map(({ bullet }) => (
                                        <li
                                            className={styles.listSquare}
                                            key={bullet}
                                        >
                                            <Text as="p" size="paragraphSm">
                                                {bullet}
                                            </Text>
                                        </li>
                                    ))}
                                </ul>
                            </Column>
                        ))}
                </Row>
            </Grid>
        </Section>
    );
};

RoadMapping.propTypes = {
    roadMapping: PropTypes.shape({
        sectionHeader: PropTypes.shape({
            eyebrow: PropTypes.string,
            title: PropTypes.string,
            description: PropTypes.string,
        }).isRequired,
        image: PropTypes.shape({
            childImageSharp: PropTypes.shape({
                fluid: PropTypes.shape({
                    aspectRatio: PropTypes.number.isRequired,
                    src: PropTypes.string.isRequired,
                    srcSet: PropTypes.string.isRequired,
                    sizes: PropTypes.string.isRequired,
                    base64: PropTypes.string,
                    tracedSVG: PropTypes.string,
                    srcWebp: PropTypes.string,
                    srcSetWebp: PropTypes.string,
                    media: PropTypes.string,
                    maxWidth: PropTypes.number,
                    maxHeight: PropTypes.number,
                }),
            }),
        }).isRequired,
    }).isRequired,
    experience: PropTypes.shape({
        bullets: PropTypes.arrayOf(PropTypes.string).isRequired,
    }).isRequired,
};

export default RoadMapping;

import React from 'react';
import Section from '@components/core/Section';
import Grid, { Row, Column } from '@components/core/Grid';
import SectionHeader from '@components/SectionHeader';
import PropTypes from 'prop-types';
import Text from '@components/core/Text';
import Markdown from 'react-remarkable';
import styles from './product-life-cycle.module.scss';

const ProductLifeCycle = ({
    sectionHeader: { eyebrow, title, description },
}) => {
    return (
        <Section className={styles.section}>
            <Grid>
                <Row>
                    <Column md="4">
                        <SectionHeader
                            className={styles.sectionHeader}
                            sizeTitle="h3"
                            eyebrow={eyebrow}
                            title={title}
                        />
                    </Column>
                    <Column md="7" offsetMd="1">
                        <Text
                            as="div"
                            size="paragraphMd"
                            className={styles.summary}
                        >
                            <Markdown>{description}</Markdown>
                        </Text>
                    </Column>
                </Row>
            </Grid>
        </Section>
    );
};

ProductLifeCycle.propTypes = {
    sectionHeader: PropTypes.shape({
        eyebrow: PropTypes.string,
        title: PropTypes.string,
        description: PropTypes.string,
    }).isRequired,
};

export default ProductLifeCycle;

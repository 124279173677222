import React from 'react';
import Quote from '@components/core/Quote';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import Text from '@components/core/Text';
import styles from './quotes.module.scss';

const Quotes = ({ author, testimony, image }) => (
    <div className={styles.wrapper}>
        <Quote size="large" variant="inlineBlock" v>
            {testimony}
        </Quote>
        <div className={styles.container}>
            <Text as="p" size="paragraphSm" className={styles.author}>
                {author}
            </Text>
            <div className={styles.fontImage}>
                <Img fluid={image} />
            </div>
        </div>
    </div>
);

Quotes.propTypes = {
    testimony: PropTypes.string.isRequired,
    author: PropTypes.string.isRequired,
    image: PropTypes.shape({
        aspectRatio: PropTypes.number.isRequired,
        src: PropTypes.string.isRequired,
        srcSet: PropTypes.string.isRequired,
        sizes: PropTypes.string.isRequired,
        base64: PropTypes.string,
        tracedSVG: PropTypes.string,
        srcWebp: PropTypes.string,
        srcSetWebp: PropTypes.string,
        media: PropTypes.string,
        maxWidth: PropTypes.number,
        maxHeight: PropTypes.number,
    }),
};

Quotes.defaultProps = {
    image: {},
};

export default Quotes;

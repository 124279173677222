import React from 'react';
import PropTypes from 'prop-types';
import { graphql, useStaticQuery } from 'gatsby';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import Grid, { Row, Column } from '@components/core/Grid';
import SectionHeader from '@components/SectionHeader';
import ParallaxSection from '@components/core/ParallaxSection';
import styles from './techPartners.module.scss';

const TechPartners = ({ data }) => {
    const breakpoints = useBreakpoint();
    const { result } = useStaticQuery(graphql`
        query GetOttTechPartnersQuery {
            result: allMarkdownRemark(
                filter: { fields: { slug: { glob: "/ott/*" } } }
            ) {
                partners: nodes {
                    frontmatter {
                        title
                        image {
                            publicURL
                        }
                    }
                }
            }
        }
    `);

    return (
        <ParallaxSection
            className={styles.section}
            variant="dark"
            dataTheme="dark"
            disabled={!breakpoints.md}
            bottomDisabled
        >
            <Grid>
                <Row>
                    <Column md="9">
                        <SectionHeader
                            variant="dark"
                            eyebrow={data.sectionHeader.eyebrow}
                            title={data.sectionHeader.title}
                            summary={data.sectionHeader.description}
                        />
                    </Column>
                </Row>

                <div className={styles.partners}>
                    {result.partners.map(
                        ({
                            frontmatter: {
                                title,
                                image: { publicURL },
                            },
                        }) => (
                            <div
                                key={title}
                                role="img"
                                aria-label={title}
                                className={styles.partner}
                            >
                                <div
                                    className={styles.partnerImage}
                                    style={{
                                        backgroundImage: `url(${publicURL})`,
                                    }}
                                />
                            </div>
                        )
                    )}
                </div>
            </Grid>
        </ParallaxSection>
    );
};

TechPartners.propTypes = {
    data: PropTypes.shape({
        sectionHeader: PropTypes.shape({
            eyebrow: PropTypes.string,
            title: PropTypes.string,
            description: PropTypes.string,
        }),
    }).isRequired,
};

export default TechPartners;
